import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { ReactComponent as ArrowRight } from './../../images/Icons/arrow-next.svg';

import styles from './groups.module.scss';

const Groups = ({ title, data, className, ...rest}) => (
    <div {...rest} className={[styles.groups, className].join(' ')}>
        {title &&
            <h3 className={styles.groupsTitle}>{title}</h3>
        }
        <ul className={styles.groupsList}>
            {data.map( (item, index) => (
                <li key={`groupsItem-${index}`} className={styles.groupsListItem}>
                    <Link className={styles.groupsLink} to={item.slug}>
                        {(item.image && item.image.childImageSharp) &&
                            <Img fixed={item.image.childImageSharp.fixed} className={styles.picto} fadeIn={false} backgroundColor="#ffffff" alt="pictogram" />
                        }
                        <span className={styles.linkLabel}>{item.title}</span>
                        <ArrowRight className={styles.linkArrow} />
                    </Link>
                </li>
            ))}
        </ul>
    </div>
);

Groups.propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        slug: PropTypes.string,
        title: PropTypes.string,
        image: PropTypes.object,
    }))
};

export default Groups;
