import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';
import {LanguageLayout} from "../containers/layouts";
import Wrapper from './../components/wrapper/';
import WrapperContent from './../components/wrapperContent/';
import PageIntro from './../components/pageIntro/';
import Groups from './../components/groups/';
import IndustryList from "../containers/industryList";

export const ExpertiseOverviewTemplate = ({ title, description, expertises }) => (
    <>
        <PageIntro color="sand">
            <Wrapper>
                <WrapperContent>
                    <PageIntro.title>{title}</PageIntro.title>
                    <PageIntro.description html>{description}</PageIntro.description>
                </WrapperContent>
            </Wrapper>
        </PageIntro>
        <Wrapper>
            <WrapperContent>
                <div>{expertises.map(expertise => {
                    return <Groups title={expertise.groupName} data={expertise.data} key={`expertise-${expertise.groupName}`} />;
                })}</div>
            </WrapperContent>
            <WrapperContent color="blue">
                <IndustryList
                    showCompanies={false}
                />
            </WrapperContent>
        </Wrapper>
    </>
);

const ExpertiseOverview = ({ location, data}) => {
    const { frontmatter, fields } = data.page;
    const { nodes } = data.expertises;
    const expertiseGroups = data.expertiseGroups.nodes;
    const groupedNodes = _.groupBy(nodes, e => (e.fields.expertise && e.fields.expertise.frontmatter) ? e.fields.expertise.frontmatter.expertiseGroupName : false);
    const expertises = [];

    expertiseGroups.map((group) => {
       if (groupedNodes[group.frontmatter.expertiseGroupName]) {
           expertises.push({
               groupName: group.frontmatter.expertiseGroupName,
               data: groupedNodes[group.frontmatter.expertiseGroupName].map((item, index) => {
                   return {
                       title: item.frontmatter.title,
                       slug: item.fields.slug,
                       image: item.frontmatter.expertiseListimage,
                   }
               }),
           });
       }
       return null;
    });

    return (
        <LanguageLayout location={location}>
            <ExpertiseOverviewTemplate
                title={frontmatter.title}
                description={fields.frontmattermd.pageDescriptionText.html}
                expertises={expertises}
            />
        </LanguageLayout>
    );
};

export default ExpertiseOverview;

export const pageQuery = graphql`
    query getExpertises($langKey: String) {
        page: markdownRemark(fields: {langKey: {eq: $langKey}}, frontmatter: {templateKey: {eq: "expertise-overview"}}) {
            fields {
                frontmattermd {
                    pageDescriptionText {
                        html
                    }
                }
            }
            frontmatter {
                title
            }
        }
        expertises: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "expertise-page"}}, fields: {langKey: {eq: $langKey}}}) {
            totalCount
            nodes {
              id
              fields {
                slug
                expertise {
                  frontmatter {
                    expertiseGroupName
                  }
                }
              }
              frontmatter {
                title
                description
                expertiseGroup
                expertiseListimage {
                  publicURL
                  childImageSharp {
                    fixed(width: 64, height: 64) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            }
        }
        expertiseGroups: allMarkdownRemark(
          sort: { fields:[frontmatter___expertiseGroupSortingOrder], order: ASC }
          filter:{
            frontmatter: {
              templateKey:{ eq:"expertiseGroup"}
              langKey:{eq: $langKey}
            }
          }
        ) {
            nodes {
                frontmatter {
                    expertiseGroupName
                    expertiseGroupSortingOrder
                }
            }
        }
    }
`;
